import { MenuContext } from '@/providers/menu-provider';
import { useContext } from 'react';

export function useMenu() {
  const menu = useContext(MenuContext);

  if (!menu) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return menu;
}
