'use client';

import type { IBookmarkItem } from '@/components/bookmarks/types';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useMenu } from '@/hooks/useMenu';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import { useBookmarkStore } from '@/providers/bookmark-provider';
import { FolderIcon, LinkIcon } from '@heroicons/react/16/solid';
import { FolderOpenIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { defaultRootMenuItems } from '../utils';
import SideBarMenuItem from './MenuItem';

export default function SidebarBookmarks() {
  const { bookmarks } = useBookmarkStore();
  const { sidebarSettings, setSidebarSettings } = useMenu();

  const renderNestedFolder = (bookmark: IBookmarkItem, index: number) => {
    const items = bookmarks.filter(
      (bm) => bm.folder === bookmark.id && !bm.hidden
    );

    if (items.length === 0)
      return (
        <AccordionItem
          className='flex items-center gap-2 border-none px-2 py-1'
          value={bookmark.id}
          key={bookmark.id}
        >
          <FolderIcon className='size-4' />
          <h3 className='font-normal'>{bookmark.name}</h3>{' '}
          <span className='text-xs'>(tom)</span>
        </AccordionItem>
      );

    return (
      <AccordionItem
        key={bookmark.id}
        className={cn('w-full border-none pl-2', index === 0 && 'pl-0')}
        value={bookmark.id}
      >
        <AccordionTrigger
          className={cn(
            'my-0.5 flex  w-full items-center justify-between gap-2 rounded px-2 py-1 text-left text-sm text-secondary-foreground transition-colors hover:bg-muted-foreground/10 hover:no-underline',
            sidebarSettings.expandedItems?.bookmarks?.at(-1) === bookmark.id &&
              'bg-accent'
          )}
        >
          <div className='flex items-center truncate'>
            {sidebarSettings.expandedItems?.bookmarks?.includes(bookmark.id) ? (
              <FolderOpenIcon className={'mr-2 size-4'} />
            ) : (
              <FolderIcon className='mr-2 size-4' />
            )}
            <span className='truncate font-normal text-foreground'>
              {bookmark.name}
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {items.map((innerBookmark, idx) => {
            if (innerBookmark.kind === 'folder') {
              return renderNestedFolder(innerBookmark, idx + 1);
            } else {
              return (
                <div className='truncate pl-2' key={innerBookmark.id}>
                  <SideBarMenuItem
                    noActiveIndication
                    route={{
                      icon: <LinkIcon className='mr-2 size-4' />,
                      title: innerBookmark.name,
                      path: innerBookmark.url,
                    }}
                  />
                </div>
              );
            }
          })}
        </AccordionContent>
      </AccordionItem>
    );
  };

  if (bookmarks.length === 0)
    return (
      <>
        <div className='group mt-4 w-full px-6'>
          <h4 className='flex items-center justify-between text-xs font-normal'>
            Bokmärken
            <Link
              prefetch={false}
              href={Urls.dashboard.bookmarks}
              className={cn(
                'invisible text-xs font-normal text-muted-foreground transition-all hover:text-foreground group-hover:visible'
              )}
            >
              Hantera
            </Link>
          </h4>
        </div>

        <div className='mx-6 mt-1 rounded-md text-xs'>Du saknar bokmärken</div>
      </>
    );

  return (
    <Accordion
      type='multiple'
      className='mt-2 px-4'
      defaultValue={defaultRootMenuItems}
      onValueChange={(values) => {
        setSidebarSettings((prev) => ({
          ...prev,
          expandedItems: {
            ...prev.expandedItems,
            root: values,
          },
        }));
      }}
      value={sidebarSettings.expandedItems?.root}
    >
      <AccordionItem className='border-none' value='bookmarks'>
        <AccordionTrigger className='my-0.5  flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-left text-sm text-foreground/90 transition-colors hover:bg-muted-foreground/10 hover:no-underline [&>svg]:opacity-0 [&>svg]:transition-all [&>svg]:hover:opacity-100'>
          <div className='group w-full'>
            <h4 className='flex items-center justify-between text-xs font-normal'>
              Bokmärken
              <Link
                prefetch={false}
                href={Urls.dashboard.bookmarks}
                className={cn(
                  'invisible text-xs font-normal text-muted-foreground transition-all hover:text-foreground group-hover:visible'
                )}
              >
                Hantera
              </Link>
            </h4>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <Accordion
            value={sidebarSettings.expandedItems?.bookmarks}
            onValueChange={(values) => {
              setSidebarSettings((prev) => ({
                ...prev,
                expandedItems: {
                  ...prev.expandedItems,
                  bookmarks: values,
                },
              }));
            }}
            type='multiple'
          >
            {bookmarks
              .filter((bm) => !bm.folder && !bm.hidden)
              .map((bm, index) => {
                if (bm.kind === 'folder') {
                  return (
                    <div key={bm.id} className='w-full'>
                      {renderNestedFolder(bm, index)}
                    </div>
                  );
                } else {
                  return (
                    <SideBarMenuItem
                      noActiveIndication
                      route={{
                        icon: <LinkIcon className='mr-2 size-4' />,
                        title: bm.name,
                        path: bm.url,
                      }}
                      key={bm.id}
                    />
                  );
                }
              })}
          </Accordion>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
