import { getApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import type { ErrorResponse } from '@/requests/types';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { RoleSegmentCombination } from '../components/workspace-selector';

export function useGetRoleSegmentCombination(
  options?: Omit<
    UseQueryOptions<
      unknown,
      ErrorResponse,
      RoleSegmentCombination[],
      Array<string>
    >,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    ...options,
    queryKey: ['roleSegmentCombination'],
    queryFn: async () => {
      return await getApi({
        url: `${BASE_BACKEND_URL}/accounts/users/role_segment_combinations/`,
        errorMessage: 'Unable to fetch role segment combination',
      });
    },
  });
}
