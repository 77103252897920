import { createContext, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type SidebarSettings = {
  menuVisibility: Record<string, boolean>;
  menuOrder: Record<string, string[]>;
  expandedItems: Record<string, string[]>;
};

interface MenuState {
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarSettings: SidebarSettings;
  setSidebarSettings: React.Dispatch<React.SetStateAction<SidebarSettings>>;
}

const initialState: MenuState = {
  isEditMode: false,
  setIsEditMode: () => undefined,
  sidebarSettings: {
    menuVisibility: {},
    menuOrder: {},
    expandedItems: {},
  },
  setSidebarSettings: () => undefined,
};

export const MenuContext = createContext(initialState);

interface MenuContextType {
  children: React.ReactNode;
}

export function MenuProvider({ children }: MenuContextType) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [sidebarSettings, setSidebarSettings] =
    useLocalStorage<SidebarSettings>('sidebarSettings', {
      menuVisibility: {},
      menuOrder: {},
      expandedItems: {},
    });

  const menuValue = useMemo(
    () => ({
      isEditMode,
      setIsEditMode,
      sidebarSettings,
      setSidebarSettings,
    }),
    [isEditMode, setSidebarSettings, sidebarSettings]
  );

  return (
    <MenuContext.Provider value={menuValue}>{children}</MenuContext.Provider>
  );
}
