import { useGetBranding } from '@/app/(auth)/hooks/use-get-branding';
import Image from 'next/image';
import { useState } from 'react';
import { Icons } from '../icons';

export function Logo() {
  const { data: branding } = useGetBranding();
  const [error, setError] = useState(false);
  if (branding?.branding[0]?.images?.logo?.signed_url_get && !error) {
    return (
      <Image
        width={100}
        height={27}
        src={branding?.branding?.[0]?.images?.logo?.signed_url_get}
        alt='Logo'
        onError={() => setError(true)}
      />
    );
  }
  return <Icons.logo />;
}
