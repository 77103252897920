import { toReadableDate } from '@/lib/dates';
import Image from 'next/image';
import logo from '../../../../../../public/assets/img/pigello-logo-notext-graphite.svg';
import type { IReleaseNote } from './release-notes-sheet-content';

export default function ReleaseNote({ note }: { note: IReleaseNote }) {
  return (
    <div className='flex items-start gap-2'>
      <Image
        src={logo}
        alt='Pigello Logo'
        width={32}
        height={32}
        className='size-8 rounded-full bg-cover bg-center object-cover object-center'
      />
      <div className='flex flex-col gap-1'>
        <div className='font-medium'>{note.title}</div>
        <div className='flex gap-2'>
          <div>{note.content}</div>
        </div>
        <p className='w-full text-xs text-muted-foreground'>
          {toReadableDate(String(note.date.toISODate()))}
        </p>
      </div>
    </div>
  );
}
