 import type { RealEstate } from '@pigello/pigello-matrix';

import { RealEstateConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetRealEstate = (
  options: useGetGenericInstanceProps<RealEstate>
) => {
  return useGetInstance<RealEstate>({
    ...options,
    config: RealEstateConfig,
    modelName: 'realestate'
  });
};

export const useGetRealEstateList = (
  options: useGetGenericListProps<RealEstate>
) => {
  return useGetList<RealEstate>({
    ...options,
    config: RealEstateConfig,
    modelName: 'realestate'
  });
};
