'use client';

import { fetchApi } from '@/requests/api';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';

import { ObjectKeys, raise } from '@/lib/utils';
import { BASE_BACKEND_URL } from '@/requests/constants';
import {
  toExternalFieldNames,
  toInternalFieldNames,
} from '@/requests/instanceMapper';
import { useQueryClient } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';
import { set } from 'lodash';
import { useState } from 'react';
import { useConfig } from './useConfig';

export default function useBulk<Instance extends BaseInstance>({
  modelName,
  disableRetryChunk = false,
}: {
  modelName: ModelName;
  disableRetryChunk?: boolean;
}) {
  const qc = useQueryClient();
  const { config } = useConfig<Instance>(modelName);
  const [total, setTotal] = useState(0);
  const [savedCount, setSavedCount] = useState(0);
  const [didSave, setDidSave] = useState(false);
  const [loading, setLoading] = useState(false);

  const [totalError, setTotalError] = useState(false);

  const reset = () => {
    setTotal(0);
    setDidSave(false);
    setSavedCount(0);
    setLoading(false);
  };

  const invalidateQueries = () => {
    qc?.invalidateQueries({
      queryKey: [modelName],
    });
    qc?.invalidateQueries({
      queryKey: [modelName, 'allinstances'],
    });
    qc?.invalidateQueries({
      queryKey: [modelName, 'list'],
    });
  };

  const saveChunk = async (
    chunk: Partial<Instance>[] = [],
    alreadySavedInstances: Partial<Instance>[] = [],
    totalFailedInstances: Partial<Instance>[] = []
  ): Promise<{ saved: Partial<Instance>[]; failed: Partial<Instance>[] }> => {
    const nextChunk: Partial<undefined | Instance>[] = [...chunk]; // errored instances will be spliced from this
    const errored = [...totalFailedInstances]; // errored instances will be inserted into this
    const saved = [...alreadySavedInstances]; // succeeded instances will be inserted into this

    const res = await fetchApi({
      method: 'POST',
      url: `${BASE_BACKEND_URL}/${config?.listUrl}/`,
      body: JSON.stringify(chunk),
    });

    const json = (await res.json()) as Instance[];

    if (res.status === 400) {
      for (let i = 0; i < json.length; i++) {
        const instanceAttempt = json[i];

        if (ObjectKeys(instanceAttempt).length > 0) {
          const errorInstance = nextChunk.splice(i, 1, undefined);

          if (errorInstance[0]) {
            errored.push(errorInstance[0]);
          }
        }
      }

      if (nextChunk.length === 0 || disableRetryChunk) {
        return { saved: saved, failed: errored };
      }

      return await saveChunk(
        nextChunk.filter((c) => c !== undefined) as Partial<Instance>[],
        saved,
        errored
      );
    } else if (res.status === 201) {
      setSavedCount((prev) => prev + json.length);
      const newInstances: Partial<Instance>[] = [];
      for (const instanceData of json) {
        if (config) {
          const newInstance = await toInternalFieldNames<Instance>(
            config,
            instanceData
          );
          newInstances.push(newInstance);
        }
      }
      return { saved: [...saved, ...newInstances], failed: errored };
    } else {
      raise('Unknown Error');
    }
  };

  const postInstances = async (chunks: Partial<Instance>[][]) => {
    if (!config) return;

    const promises = chunks.map((c) => saveChunk(c));

    const res = await Promise.allSettled(promises);

    let succeededInstances: Partial<Instance>[] = [];
    let failedInstances: Partial<Instance>[] = [];

    for (const cur of res) {
      if (cur.status === 'fulfilled') {
        succeededInstances = [...succeededInstances, ...cur.value.saved];
        failedInstances = [...failedInstances, ...cur.value.failed];
      } else {
        setTotalError(true);
      }
    }

    return { succeededInstances, failedInstances };
  };

  const save = async ({
    chunkSize = 500,
    instances,
  }: {
    chunkSize?: number;

    instances: Partial<Instance>[];
  }) => {
    if (!config) return;
    try {
      setLoading(true);

      const snakedInstances: Partial<Instance>[] = [];

      for (let i = 0; i < instances.length; i++) {
        snakedInstances.push(await toExternalFieldNames(config, instances[i]));
      }

      // set organization id for all instances that are POST
      snakedInstances.forEach((instance) => {
        if (!instance.id) {
          set(instance, 'organization.id', getCookie('organization_id'));
        }
      });

      const chunks: Partial<Instance>[][] = [];
      // build chunks
      for (let i = 0; i < snakedInstances.length; i += chunkSize) {
        const curChunk = snakedInstances.slice(i, i + chunkSize);
        chunks.push(curChunk);
      }

      setTotal(chunks.flatMap((c) => c).length);
      const res = await postInstances(chunks);

      if (!res) {
        invalidateQueries();
        setLoading(false);
        setTotalError(true);
        setDidSave(false);
        setSavedCount(0);
        return {
          error: true,
          didSave: false,
          succeededInstances: [],
          failedInstances: [],
        };
      }

      const { succeededInstances: succeededInstances, failedInstances } = res;
      setLoading(false);
      setDidSave(true);

      invalidateQueries();

      return {
        succeededInstances,
        failedInstances,
        didSave: true,
        error: false,
      };
    } catch (e) {
      invalidateQueries();

      setLoading(false);
      return {
        succeededInstances: [],
        failedInstances: [],
        didSave: false,
        error: true,
      };
    }
  };

  return {
    reset,
    loading,
    hasError: totalError,
    save,
    didSave,
    progress: total > 0 ? Math.round((savedCount / total) * 100) : 0,
  };
}
