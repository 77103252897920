import { cn } from '@/lib/cn';
import {
  BookOpenIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  RectangleStackIcon,
} from '@heroicons/react/16/solid';
import type { TabBarStates } from './global-filter-modal';

export default function TabBar({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: TabBarStates;
  setSelectedTab: (tab: TabBarStates) => void;
}) {
  return (
    <div className='relative grid grid-cols-4 gap-4 overflow-hidden rounded-lg bg-muted p-2'>
      <div
        className={cn(
          'absolute top-[2px] h-[calc(100%-4px)] w-1/4 rounded bg-background transition-all',
          {
            'translate-x-[2%]': selectedTab === 'externalclients',
            'translate-x-full': selectedTab === 'companies',
            'translate-x-[205%]': selectedTab === 'segments',
            'translate-x-[298%]': selectedTab === 'realestates',
          }
        )}
      >
        &nbsp;
      </div>
      <button
        onClick={() => setSelectedTab('externalclients')}
        className={cn(
          'z-10 col-span-1 flex justify-center rounded-l text-xs transition-all',
          {
            'text-primary': selectedTab === 'externalclients',
          }
        )}
      >
        <div className='flex items-center'>
          <BookOpenIcon className='mr-1 size-3' /> Uppdragsgivare
        </div>
      </button>
      <button
        onClick={() => setSelectedTab('companies')}
        className={cn(
          'z-10 col-span-1 flex justify-center rounded-l text-xs transition-all',
          {
            'text-primary': selectedTab === 'companies',
          }
        )}
      >
        <div className='flex items-center'>
          <RectangleStackIcon className='mr-1 size-3' /> Bolag
        </div>
      </button>
      <button
        onClick={() => setSelectedTab('segments')}
        className={cn(
          'z-10 col-span-1 flex justify-center rounded-l  text-xs transition-all',
          {
            'text-primary': selectedTab === 'segments',
          }
        )}
      >
        <div className='flex items-center'>
          <BriefcaseIcon className='mr-1 size-3' /> Segment
        </div>
      </button>
      <button
        onClick={() => setSelectedTab('realestates')}
        className={cn(
          'z-10 col-span-1 flex  justify-center rounded-r text-xs transition-all',
          {
            'text-primary': selectedTab === 'realestates',
          }
        )}
      >
        <div className='flex items-center'>
          <BuildingOfficeIcon className='mr-1 size-3' /> Fastigheter
        </div>
      </button>
    </div>
  );
}
