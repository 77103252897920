'use client';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useMenu } from '@/hooks/useMenu';
import { useManyPerms, usePerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import { raise } from '@/lib/utils';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Bars3Icon } from '@heroicons/react/16/solid';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import type { ISubMenu } from '../data';
import { menuData } from '../data';
import { reorder } from '../utils';
import NotificationIndicator from './notification-indicator';
import { SubMenuItem } from './sub-menu-item';
import type { CheckedState } from './types';

interface SubMenuProps {
  menu: ISubMenu;
  subMenus: ISubMenu[];
  parentKey: string;
  index: number;
  notifications?: Record<string, number | boolean | undefined>;
}

export function SubMenu({
  index,
  menu,
  subMenus,
  parentKey,
  notifications,
}: SubMenuProps) {
  // ------------------ Hooks ------------------
  const { data: self } = useGetSelf();
  const { isEditMode, sidebarSettings, setSidebarSettings } = useMenu();
  const pathname = usePathname();
  const modelName = useMemo(() => menu.modelName, [menu]);
  const { canView } = usePerms(modelName);
  const modelNames = menu.modelNames || [];
  const { perms } = useManyPerms(modelNames);

  // ------------------ Derived States ------------------
  const isPigelloUser =
    (self?.email?.endsWith('@pigello.se') && self?.emailVerified) ?? false;

  const hasNoPerms =
    perms.every((perm) => !perm.canView) && modelNames.length > 0;

  const { perms: additionalViewPerms } = useManyPerms(
    menu.additionalModelNames ?? []
  );
  const hasAllAdditionalViewPerms = additionalViewPerms.every((p) => p.canView);

  const isNested = menu.subMenus && menu.subMenus.length > 0;
  const hasNotificationsNested =
    notifications && notifications[menu.key] != null;

  const isActive = menu.path
    ? pathname.endsWith(menu.path.split('/').pop() || '')
    : false;

  // ------------------ Handlers ------------------

  const handleDragEnd = (result: DropResult, key: string) => {
    if (!result.destination) return;

    const currentOrder =
      sidebarSettings.menuOrder?.[key] ||
      menuData[parentKey as keyof typeof menuData].subMenus
        .find((item) => item.key === key)
        ?.subMenus?.map((item) => item.key) ||
      [];

    const newOrder = reorder(
      currentOrder,
      result.source.index,
      result.destination.index
    );
    setSidebarSettings((prev) => ({
      ...prev,
      menuOrder: {
        ...prev.menuOrder,
        [key]: newOrder,
      },
    }));
  };

  const toggleMenuVisibility = (checked: CheckedState, subMenu: ISubMenu) => {
    const newVisibility = {
      ...sidebarSettings.menuVisibility,
      [subMenu.key]: !checked,
    };

    const allSubMenusVisible =
      menu.subMenus?.every((sm) => newVisibility[sm.key]) ?? false;

    if (allSubMenusVisible) {
      setSidebarSettings((prev) => ({
        ...prev,
        menuVisibility: {
          ...prev.menuVisibility,
          [subMenu.key]: true,
          [menu.key]: true,
        },
      }));
      return;
    }

    setSidebarSettings((prev) => ({
      ...prev,
      menuVisibility: newVisibility,
    }));
  };

  const toggleParentMenuVisibility = (
    checked: CheckedState,
    parentKey: string
  ) => {
    const newVisibility: Record<string, boolean> = {
      ...sidebarSettings.menuVisibility,
      [menu.key]: !checked,
      ...menu.subMenus?.reduce(
        (acc, { key }) => ({ ...acc, [key]: !checked }),
        {}
      ),
    };

    setSidebarSettings((prev) => ({
      ...prev,
      menuVisibility: newVisibility,
    }));

    const allSubMenusVisible = subMenus.every(({ key }) => newVisibility[key]);

    if (allSubMenusVisible) {
      setSidebarSettings((prev) => ({
        ...prev,
        menuVisibility: {
          ...prev.menuVisibility,
          [parentKey]: true,
        },
      }));
    }
  };

  // ------------------ Early Returns ------------------
  if (
    hasNoPerms ||
    (!canView && !!menu.modelName) ||
    menu.hidden ||
    (menu.pigelloUserOnly && !isPigelloUser) ||
    (additionalViewPerms.length > 0 && !hasAllAdditionalViewPerms)
  )
    return null;

  // ------------------ Render Nested Menu ------------------
  if (isNested) {
    return (
      <Draggable key={menu.key} index={index} draggableId={menu.key}>
        {(provided) => (
          <div
            className={cn('group/root-item flex w-full items-start gap-1')}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...(!isEditMode && provided.dragHandleProps)}
          >
            {/* Drag Handle */}
            {isEditMode && (
              <button
                {...provided.dragHandleProps}
                className={cn(
                  'invisible ml-1 mt-1.5 cursor-grab transition-opacity group-hover/root-item:visible'
                )}
              >
                <Bars3Icon className='size-4' />
              </button>
            )}

            {/* Visibility Checkbox */}
            {isEditMode && (
              <Checkbox
                disabled={sidebarSettings.menuVisibility?.[parentKey]}
                checked={!sidebarSettings.menuVisibility?.[menu.key]}
                onCheckedChange={(checked) =>
                  toggleParentMenuVisibility(checked, parentKey)
                }
                className='mt-1.5 size-4'
              />
            )}

            {/* Accordion for SubMenus */}
            <Accordion
              value={sidebarSettings.expandedItems?.[menu.key]}
              onValueChange={(values) => {
                setSidebarSettings((prev) => ({
                  ...prev,
                  expandedItems: {
                    ...prev.expandedItems,
                    [menu.key]: values,
                  },
                }));
              }}
              className='w-full'
              type='multiple'
            >
              <DragDropContext onDragEnd={(e) => handleDragEnd(e, menu.key)}>
                <AccordionItem
                  className='grow border-none '
                  value={menu.title}
                  key={menu.key}
                >
                  <AccordionTrigger
                    disabled={sidebarSettings.menuVisibility?.[menu.key]}
                    className={cn(
                      'my-0.5 flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-left text-foreground transition-colors hover:no-underline',
                      sidebarSettings.menuVisibility?.[menu.key] &&
                        'opacity-50',
                      !sidebarSettings.menuVisibility?.[menu.key] &&
                        'hover:bg-muted-foreground/10'
                    )}
                  >
                    <div className='flex w-full items-center gap-1'>
                      {menu.icon}
                      <div className='flex w-full items-center justify-between truncate text-sm font-normal text-foreground'>
                        {menu.title}
                        {hasNotificationsNested && !isEditMode && (
                          <NotificationIndicator
                            count={notifications[menu.key]}
                          />
                        )}
                      </div>
                    </div>
                  </AccordionTrigger>

                  <AccordionContent className='my-1 pb-0'>
                    <Droppable
                      isDropDisabled={!isEditMode}
                      type={menu.key}
                      droppableId={menu.key}
                    >
                      {(provided) => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {menu.subMenus
                            ?.filter((subMenu) =>
                              isEditMode
                                ? true
                                : !sidebarSettings.menuVisibility?.[
                                    subMenu.key
                                  ] &&
                                  (!subMenu.modelName ||
                                    perms[modelNames.indexOf(subMenu.modelName)]
                                      ?.canView)
                            )
                            .sort(
                              (a, b) =>
                                (sidebarSettings.menuOrder?.[menu.key]?.indexOf(
                                  a.key
                                ) || 0) -
                                (sidebarSettings.menuOrder?.[menu.key]?.indexOf(
                                  b.key
                                ) || 0)
                            )
                            .map((subMenu, idx) => (
                              <Draggable
                                key={subMenu.key}
                                index={idx}
                                draggableId={subMenu.key}
                                isDragDisabled={!isEditMode}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...(!isEditMode &&
                                      provided.dragHandleProps)}
                                    className={cn(
                                      'group/item flex items-start gap-1',
                                      !isEditMode && 'pl-6'
                                    )}
                                  >
                                    {/* Drag Handle */}
                                    {isEditMode && (
                                      <button
                                        {...provided.dragHandleProps}
                                        className={cn(
                                          'invisible mt-1.5 cursor-grab transition-opacity group-hover/item:visible'
                                        )}
                                      >
                                        <Bars3Icon className='size-4' />
                                      </button>
                                    )}

                                    {/* Visibility Checkbox */}
                                    {isEditMode && (
                                      <Checkbox
                                        checked={
                                          !sidebarSettings.menuVisibility?.[
                                            subMenu.key
                                          ]
                                        }
                                        disabled={
                                          sidebarSettings.menuVisibility?.[
                                            menu.key
                                          ] === true &&
                                          sidebarSettings.menuVisibility?.[
                                            subMenu.key
                                          ]
                                        }
                                        onCheckedChange={(checked) =>
                                          toggleMenuVisibility(checked, subMenu)
                                        }
                                        className='mt-1.5 size-4'
                                      />
                                    )}

                                    {/* SubMenu Item */}
                                    <SubMenuItem
                                      key={`${subMenu.title}-${subMenu.path}`}
                                      subMenu={subMenu}
                                      notifications={notifications}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </AccordionContent>
                </AccordionItem>
              </DragDropContext>
            </Accordion>
          </div>
        )}
      </Draggable>
    );
  }

  // ------------------ Render Single Menu Item ------------------
  const path = menu.path;
  if (!path) {
    raise(`menu path is undefined in SidebarMenuItem ${menu.title}`);
  }
  const hasNotificationsSingle = notifications && notifications[path] != null;

  return (
    <Draggable key={menu.key} index={index} draggableId={menu.key}>
      {(provided) => (
        <div
          className={cn('group/root-item flex w-full items-start gap-1')}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...(!isEditMode && provided.dragHandleProps)}
        >
          {/* Drag Handle */}
          {isEditMode && (
            <button
              {...provided.dragHandleProps}
              className={cn(
                'invisible ml-1 mt-1.5 cursor-grab transition-opacity group-hover/root-item:visible'
              )}
            >
              <Bars3Icon className='size-4' />
            </button>
          )}

          {/* Visibility Checkbox */}
          {isEditMode && (
            <Checkbox
              disabled={sidebarSettings.menuVisibility?.[parentKey]}
              checked={!sidebarSettings.menuVisibility?.[menu.key]}
              onCheckedChange={(checked) => toggleMenuVisibility(checked, menu)}
              className='mt-1.5 size-4'
            />
          )}

          {/* Single Menu Link */}
          <ul key={menu.key} className='grow'>
            <Link
              prefetch={false}
              aria-disabled={menu.disabled}
              className={cn(
                (menu.disabled || sidebarSettings.menuVisibility?.[menu.key]) &&
                  'pointer-events-none opacity-50',
                isEditMode && 'pointer-events-none'
              )}
              href={path}
            >
              <li
                className={cn(
                  'my-0.5 flex w-full items-center justify-between gap-2 truncate rounded px-2 py-1 text-left text-sm text-foreground transition-colors hover:bg-muted-foreground/10 ',
                  isActive && 'bg-accent'
                )}
              >
                <div className='flex w-full items-center gap-1 transition-all duration-200 active:ml-0.5'>
                  {menu.icon}
                  <p className='text-foreground'>{menu.title}</p>
                  {hasNotificationsSingle && !isEditMode && (
                    <NotificationIndicator count={notifications?.[path]} />
                  )}
                </div>
              </li>
            </Link>
          </ul>
        </div>
      )}
    </Draggable>
  );
}
