'use client';

import { ObjectKeys } from '@/lib/utils';
import { fetchApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import type { Meta } from '@/requests/types';
import type { SignableDocument } from '@pigello/pigello-matrix';
import { useQuery } from '@tanstack/react-query';
import { camelCase } from 'lodash';

export default function useGetPendingSignings({
  countOnly,
}: {
  countOnly?: boolean;
} = {}) {
  const fetchPendingSignings = async () => {
    const res = await fetchApi({
      url: `${BASE_BACKEND_URL}/documents/signing/signabledocument/tosign/?_page=1&_page_size=${countOnly ? 1 : 500}&signing_aborted_time__isnull=true&rejected_time__isnull=true&signed_time__isnull=true&_order_by=-signing_started_time`,
      method: 'GET',
    });

    const { data, meta } = await res.json();

    const cameled = data.map((dp: object) => {
      return ObjectKeys(dp).reduce<Record<string, unknown>>((acc, cur) => {
        acc[camelCase(cur as string)] = dp[cur];

        return acc;
      }, {});
    });

    return { data: cameled as SignableDocument[], meta };
  };

  const { data: pending, isLoading: loadingPending } = useQuery<{
    data: SignableDocument[];
    meta: Meta;
  }>({
    queryKey: [`pending-signings_${countOnly ? 'count' : 'list'}`],
    queryFn: fetchPendingSignings,
    staleTime: countOnly ? 60 * 5 * 1000 : undefined,
  });

  return {
    count: pending?.meta.total_amount ?? 0,
    loading: loadingPending,
    data: pending,
  };
}
