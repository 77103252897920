import { SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import ReleaseNote from './release-note';

export interface IReleaseNote {
  date: DateTime;
  title: string;
  content: ReactNode;
}

const RELEASE_NOTES: IReleaseNote[] = [
  {
    date: DateTime.fromISO('2024-09-01'),
    title: 'Välkomna till Pigello',
    content: (
      <div className='flex flex-col space-y-2'>
        <p>
          Här kommer det löpande postas uppdateringar om vad som sker i
          systemet, i form av ny funktionalitet, buggfixar, och övriga
          ändringar.
        </p>

        <p>
          Har ni feedback, hittat något som inte fungerar som tänkt, eller
          övriga funderingar?
          <br />
          Välkomna att kontakta oss för att dela med er!
        </p>
      </div>
    ),
  },
];

export function ReleaseNotesheetContent() {
  return (
    <>
      <SheetHeader className='flex flex-row items-center justify-between border-b pb-4'>
        <SheetTitle className='flex w-full items-center space-x-2'>
          <div className='mr-auto'>Release notes</div>
        </SheetTitle>
      </SheetHeader>

      <div className='flex h-full flex-col gap-6 pt-4'>
        {RELEASE_NOTES.map((note) => (
          <ReleaseNote key={note.date.toUnixInteger()} note={note} />
        ))}
      </div>
    </>
  );
}
