import { useGetExternalClientList } from '@/config/organization/externalclient/client';
import { useEffect, useState } from 'react';
import { Pagination } from '../pagination';
import type { GlobalFilterItem } from './global-filter-modal';
import {
  GlobalFilerListItemSkeleton,
  GlobalFilterListItem,
} from './filter-list-item';

export default function FilterExternalClients({
  search,
  onToggle,
  filteredItems,
}: {
  search: string;
  onToggle: (id: string, displayString: string) => void;
  filteredItems: GlobalFilterItem[];
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const { data } = useGetExternalClientList({
    queryParams: {
      page,
      pageSize: 25,
      search,
      skipGlobalFilters: true,
    },
  });

  if (!data?.list)
    return Array.from({ length: 10 }).map((_, index) => (
      <GlobalFilerListItemSkeleton key={index} />
    ));

  return (
    <div>
      {data.list.map((externalClient) => {
        const isSelected = Boolean(
          filteredItems.find(
            (fi) =>
              fi.type === 'filter_external_clients' &&
              fi.id === externalClient.id
          )
        );

        return (
          <GlobalFilterListItem
            key={externalClient.id}
            item={externalClient}
            isSelected={isSelected}
            onToggle={onToggle}
          />
        );
      })}

      {data.meta.page_amount > 1 && (
        <div className='bg-muted p-2'>
          <Pagination {...{ page, setPage, meta: data.meta }} />
        </div>
      )}
    </div>
  );
}
