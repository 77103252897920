import { useGetRealEstateList } from '@/config/objects/structural/realestate/client';
import { useEffect, useState } from 'react';
import { Pagination } from '../pagination';
import type { GlobalFilterItem } from './global-filter-modal';
import {
  GlobalFilerListItemSkeleton,
  GlobalFilterListItem,
} from './filter-list-item';
import { Skeleton } from '../ui/skeleton';

export default function FilterRealEstates({
  search,
  onToggle,
  filteredItems,
}: {
  search: string;
  onToggle: (id: string, displayString: string) => void;
  filteredItems: GlobalFilterItem[];
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const { data } = useGetRealEstateList({
    queryParams: {
      page,
      pageSize: 25,
      search,
      skipGlobalFilters: true,
    },
    nested: ['mainAddress'],
  });

  if (!data?.list)
    return Array.from({ length: 10 }).map((_, index) => (
      <GlobalFilerListItemSkeleton key={index}>
        <Skeleton className='h-3 w-40' />
      </GlobalFilerListItemSkeleton>
    ));

  return (
    <div>
      {data.list.map((realEstate) => {
        const isSelected = Boolean(
          filteredItems.find(
            (fi) => fi.type === 'global_realestates' && fi.id === realEstate.id
          )
        );
        return (
          <GlobalFilterListItem
            key={realEstate.id}
            item={realEstate}
            isSelected={isSelected}
            onToggle={onToggle}
          >
            {realEstate.mainAddress && (
              <p className='mt-1 text-xs'>
                {`${realEstate.mainAddress.street}, ${realEstate.mainAddress.city}`}
              </p>
            )}
          </GlobalFilterListItem>
        );
      })}

      {data.meta.page_amount > 1 && (
        <div className='bg-muted p-2'>
          <Pagination {...{ page, setPage, meta: data.meta }} />
        </div>
      )}
    </div>
  );
}
