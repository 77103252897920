'use client';
import { settingsMenuData } from '@/app/settings/data';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { buttonVariants } from '@/components/ui/button';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useMenu } from '@/hooks/useMenu';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import Link from 'next/link';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../ui/accordion';
import { SubMenuSettings } from './sub-menu-settings';

export default function SettingsNavigation() {
  const notifications = {};

  const { sidebarSettings, setSidebarSettings } = useMenu();

  const { data: profileData, isPending } = useGetSelf();

  return (
    <>
      <div className='mt-4 flex w-full items-center gap-4 px-4'>
        <div className='flex w-full items-center gap-3 border-b pb-4'>
          <Avatar className='size-8'>
            <AvatarImage
              width={32}
              height={32}
              src={profileData?.profilePicture?.get}
              alt={profileData?.communicationName}
            />
            <AvatarFallback>
              {profileData?.firstName?.[0] ?? 'A'}
              {profileData?.lastName?.[0] ?? 'N'}
            </AvatarFallback>
          </Avatar>
          <Link
            prefetch={false}
            className={cn(
              buttonVariants({ variant: 'link' }),
              'p-0 text-base text-foreground'
            )}
            href={Urls.settings.profile.settings}
          >
            {!isPending && profileData?.communicationName}
            {!isPending && !profileData?.communicationName && 'Användare'}
          </Link>
        </div>
      </div>
      <Accordion
        className='space-y-4 overflow-y-scroll p-4 pt-0 scrollbar-hide'
        type='multiple'
        defaultValue={[
          'admin',
          'security',
          'globalConfiguration',
          'organizationsettings',
          'modulesettings',
        ]}
        value={sidebarSettings.expandedItems?.['settings_root']}
        onValueChange={(values) =>
          setSidebarSettings((prev) => ({
            ...prev,
            expandedItems: {
              ...prev.expandedItems,
              settings_root: values,
            },
          }))
        }
      >
        {Object.values(settingsMenuData).map((value) => {
          return (
            <AccordionItem
              className='w-full border-none'
              value={value.key}
              key={value.key}
            >
              <div
                className={cn(
                  'mt-2 flex w-full items-center rounded py-1 pr-2 text-left text-sm text-secondary-foreground transition-colors hover:bg-muted-foreground/10'
                )}
              >
                <AccordionTrigger
                  className={cn(
                    ' p-0 text-left  text-foreground/90 hover:no-underline [&>svg]:opacity-0 [&>svg]:transition-all [&>svg]:hover:opacity-100 '
                  )}
                >
                  <span className='w-full pl-2 text-xs font-normal'>
                    {value.title}
                  </span>
                </AccordionTrigger>
              </div>

              <AccordionContent>
                {value.subMenus.map((menu) => (
                  <SubMenuSettings
                    menu={menu}
                    notifications={notifications}
                    key={menu.key}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
}
