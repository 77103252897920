import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useMutateNotification } from '@/config/communication/notification/mutate';
import { useToast } from '@/hooks/useToast';
import { cn } from '@/lib/cn';
import { toReadableDateTime } from '@/lib/dates';
import { fetchApi } from '@/requests/api';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { type Notification } from '@pigello/pigello-matrix';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

export function Notification({
  notification,
  setSheetOpen,
}: {
  notification: Notification;
  setSheetOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();
  const router = useRouter();
  const { mutate } = useMutation<{ redirectUrl: string }>({
    mutationFn: async () => {
      const res = await fetchApi({
        url: `/api/notifications/${notification.id}`,
        method: 'GET',
      });
      if (!res.ok) throw new Error('Failed to fetch notification data');
      return await res.json();
    },
    onSuccess: ({ redirectUrl }) => {
      markAsRead({
        id: notification.id,
        body: { read: true },
      });
      if (redirectUrl) {
        router.push(redirectUrl);
        setSheetOpen(false);
      }
    },
    onError: () => {
      addToast({
        title: 'Misslyckades med att hämta notisen',
        type: 'error',
      });
    },
  });
  const { mutate: markAsRead } = useMutateNotification({
    onError: () => {
      addToast({
        title: 'Misslyckades med att markera som läst',
        type: 'error',
      });
    },
  });

  return (
    <div
      tabIndex={0}
      role='button'
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          mutate();
        }
      }}
      onClick={() => mutate()}
    >
      <div
        className={cn(
          'group flex items-center gap-2 rounded-md p-2 text-left transition-colors hover:bg-muted',
          notification.read && 'opacity-70'
        )}
      >
        <div className='flex w-full items-center justify-between gap-2'>
          <div className='flex flex-col gap-0.5'>
            <p className='font-medium'>{notification.title}</p>
            <p>
              {notification.content.length > 50 && !expanded
                ? `${notification.content.substring(0, 50)}...`
                : notification.content}
            </p>
            <p className='w-full text-xs text-muted-foreground'>
              {toReadableDateTime(notification.createdAt)}
            </p>
          </div>

          {!expanded && notification.content.length > 50 && (
            <Button
              size='sm'
              variant='link'
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(true);
              }}
              className='h-auto whitespace-nowrap p-0'
            >
              Visa mer
            </Button>
          )}
          <div className='flex items-center gap-2'>
            <DropdownMenu open={open} onOpenChange={setOpen}>
              <DropdownMenuTrigger asChild>
                <Button
                  className='invisible rounded-full focus-within:visible group-hover:visible data-[state=open]:visible'
                  size='icon'
                  variant='ghost'
                >
                  <EllipsisHorizontalIcon className='size-7' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end'>
                <DropdownMenuItem
                  onClick={(e) => {
                    markAsRead({
                      id: notification.id,
                      body: { read: !notification.read },
                    });
                    setOpen(false);
                    e.stopPropagation();
                  }}
                >
                  Markera som {notification.read ? 'oläst' : 'läst'}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            {!notification.read && (
              <div className='size-2.5 rounded-full bg-primary' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
