import { Accordion } from '@/components/ui/accordion';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { type Role, type Segment } from '@pigello/pigello-matrix';
import { getCookie } from 'cookies-next';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { RoleSegmentCombination } from './role-segment-combination';

export type RoleSegmentCombination = {
  role: Role;
  segments: Array<Segment>;
};

export function WorkspaceSelector({
  isOpen,
  setIsOpen,
  roleSegmentCombination,
  isSideBar,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  roleSegmentCombination: Array<RoleSegmentCombination>;
  isSideBar?: boolean;
}) {
  const [accordionItemOpen, setAccordionItemOpen] = useState('');
  const segments = getCookie('segments')?.split(',');
  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && !isSideBar) {
          // clearCookies();
        }
        setIsOpen(open);
      }}
    >
      {isSideBar && (
        <SheetTrigger className='flex grow items-center justify-between rounded-md border px-2 py-1 transition-colors hover:bg-muted-foreground/10'>
          <span className='text-xs text-muted-foreground'>
            {
              roleSegmentCombination?.find((segmentCombination) =>
                segmentCombination.segments.some((seg) =>
                  segments?.includes(String(seg.id))
                )
              )?.role.name
            }
          </span>
          <ChevronDownIcon className='size-3' />
        </SheetTrigger>
      )}
      <SheetContent className='flex flex-col gap-4'>
        <SheetHeader>
          <SheetTitle className='border-b pb-2'>Välj workspace</SheetTitle>
          <SheetDescription>
            En workspace är något utav det finaste vi har här på pigello. Efter
            frukosten på onsdagar, såklart!
          </SheetDescription>
        </SheetHeader>
        <Accordion
          onValueChange={setAccordionItemOpen}
          className='grid gap-2'
          collapsible={true}
          type='single'
        >
          {roleSegmentCombination?.map((segmentCombination) => (
            <RoleSegmentCombination
              key={segmentCombination.role.id}
              segmentCombination={segmentCombination}
              accordionItemOpen={accordionItemOpen}
              isSideBar={isSideBar}
              setIsOpen={setIsOpen}
            />
          ))}
        </Accordion>
      </SheetContent>
    </Sheet>
  );
}
