 import type { MonitoringConfiguration } from '@pigello/pigello-matrix';

import { MonitoringConfigurationConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetMonitoringConfiguration = (
  options: useGetGenericInstanceProps<MonitoringConfiguration>
) => {
  return useGetInstance<MonitoringConfiguration>({
    ...options,
    config: MonitoringConfigurationConfig,
    modelName: 'monitoringconfiguration'
  });
};

export const useGetMonitoringConfigurationList = (
  options: useGetGenericListProps<MonitoringConfiguration>
) => {
  return useGetList<MonitoringConfiguration>({
    ...options,
    config: MonitoringConfigurationConfig,
    modelName: 'monitoringconfiguration'
  });
};
