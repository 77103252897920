import { XMarkIcon } from '@heroicons/react/16/solid';
import type { GlobalFilterItem } from './global-filter-modal';
import { AnimatePresence, motion, Reorder } from 'framer-motion';
import { useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export default function FilteredItems({
  items,
  toggleFilteredItem,
  clearAll,
}: {
  items: GlobalFilterItem[];
  toggleFilteredItem: (item: GlobalFilterItem) => void;
  clearAll: () => void;
}) {
  const childRef = useRef<HTMLDivElement>(null);
  const childSize = useResizeObserver({
    ref: childRef,
    box: 'border-box',
  });

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{
        height: childRef.current ? childSize.height : 'auto',
        originY: 0,
        opacity: 1,
      }}
      exit={{ height: 0, opacity: 0 }}
      className='flex flex-col overflow-hidden border-b bg-background'
    >
      <div ref={childRef} className='flex flex-col gap-1'>
        <Reorder.Group
          values={items}
          onReorder={() => null}
          as='div'
          axis='x'
          className='flex flex-wrap items-center px-2 pt-2 text-xs'
        >
          <AnimatePresence>
            {items.map((i) => (
              <Reorder.Item
                as='div'
                value={i.displayString}
                id={i.id}
                key={i.id}
                className='mb-1 mr-1 flex items-center rounded-sm border bg-muted px-2 py-1'
                initial={{ opacity: 0, y: 30, scale: 0.9 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                  transition: { duration: 0.15 },
                }}
              >
                <div className='text-foreground/70'>
                  {i.type === 'global_realestates'
                    ? 'Fastighet'
                    : i.type === 'global_segments'
                      ? 'Segment'
                      : i.type === 'filter_external_clients'
                        ? 'Uppdragsgivare'
                        : 'Bolag'}
                </div>
                <div className='mx-2 min-h-[16px] min-w-px bg-foreground/20' />
                <div>{i.displayString}</div>
                <button
                  onClick={() => toggleFilteredItem(i)}
                  className='ml-1 rounded-sm border bg-background transition-all hover:bg-destructive hover:text-background'
                >
                  <XMarkIcon className='size-4' />
                </button>
              </Reorder.Item>
            ))}
          </AnimatePresence>
        </Reorder.Group>

        <div className='flex w-full justify-end px-2 pb-2'>
          <button
            onClick={clearAll}
            className=' flex items-center text-xs hover:text-destructive'
          >
            Rensa alla <XMarkIcon className='ml-1 size-3' />
          </button>
        </div>
      </div>
    </motion.div>
  );
}
