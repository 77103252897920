export default function SidebarLoader() {
  const topArea = () => (
    <>
      <div className=' col-span-2 h-8 w-full rounded bg-foreground/20'></div>
      <div className=' col-span-3 h-10 w-full rounded bg-foreground/20'></div>
      <div className=' col-span-3 h-6 w-full rounded bg-foreground/20'></div>
    </>
  );

  const divider = () => (
    <div className='col-span-3 h-px w-full bg-foreground/20'></div>
  );

  const section = () => {
    return (
      <div className='col-span-3 mt-3 grid grid-cols-3 gap-4'>
        <div className=' col-span-1 h-4 w-full rounded bg-foreground/20'></div>
        <div className='invisible col-span-1'></div>
        <div className=' col-span-2 h-4 w-full rounded bg-foreground/20'></div>
        <div className='invisible col-span-1'></div>
        <div className=' col-span-1 h-4 w-full rounded bg-foreground/20'></div>
        <div className='invisible col-span-1'></div>
        <div className=' col-span-2 h-4 w-full rounded bg-foreground/20'></div>
      </div>
    );
  };

  const floatingMenu = () => (
    <div className='absolute inset-x-6  bottom-6 h-10 rounded bg-foreground/20' />
  );

  return (
    <div className='grid animate-pulse grid-cols-3 gap-2 p-6'>
      {topArea()}
      {section()}
      {divider()}
      {section()}
      {divider()}
      {section()}
      {floatingMenu()}
    </div>
  );
}
