'use client';
import { BASE_ADMIN_URL } from '@/requests/constants';
import { useQuery } from '@tanstack/react-query';
import { getCookie, setCookie } from 'cookies-next';
import queryString from 'query-string';

type BrandingImage = {
  key: string;
  key_prefix: string;
  key_suffix: string;
  md5_hash: string;
  processed: boolean;
  signed_url_get?: string;
  signed_url_post?: {
    fields: Record<string, string>;
    url: string;
  };
};

type Branding = {
  branding: {
    name: string;
    domain: string;
    images?: Record<string, BrandingImage>;
    colors?: Record<string, string>;
    texts: null;
    links: Record<string, string>;
    google_tag_id: null;
  }[];
  customer_id: string;
};

export function useGetBranding() {
  let domain = '';
  if (typeof window !== 'undefined') {
    domain = window.location.hostname;
  }
  const hasBranding = getCookie('branding');
  return useQuery<Branding, unknown>({
    queryKey: ['branding'],
    queryFn: async () => {
      try {
        const queryParams = queryString.stringify(
          {
            customer_domain: domain,
            attributes: ['branding', 'customer_id'],
          },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'comma',
          }
        );
        const res = await fetch(`${BASE_ADMIN_URL}/customer?${queryParams}`);
        if (!res.ok) {
          if (res.status === 404) {
            setCookie('branding', 'false', {
              maxAge: 60 * 60 * 24, // 24 hours
              sameSite: 'strict',
              path: '/',
            });
            return null;
          }
          throw new Error('Failed to fetch branding');
        }
        return res.json();
      } catch (e) {
        return null;
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!domain && hasBranding !== 'false',
  });
}

export function hexToHsl(hex?: string) {
  // Ensuring the hex color string is valid
  if (!hex) return [0, 0, 0];
  if (!/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hex)) {
    throw new Error('Invalid HEX color.');
  }

  // Convert hex to RGB
  let r: number = 0,
    g: number = 0,
    b: number = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  r /= 255;
  g /= 255;
  b /= 255;

  // Find min and max values
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;
  let h: number = 0,
    s: number = 0,
    l: number = 0;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return `${h} ${s}% ${l}%`;
}
