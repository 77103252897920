import { useGetSegmentList } from '@/config/objects/segments/segment/client';
import { useEffect, useState } from 'react';
import { Pagination } from '../pagination';
import type { GlobalFilterItem } from './global-filter-modal';
import { Skeleton } from '../ui/skeleton';
import {
  GlobalFilerListItemSkeleton,
  GlobalFilterListItem,
} from './filter-list-item';

export default function FilterSegments({
  search,
  onToggle,
  filteredItems,
}: {
  search: string;
  onToggle: (id: string, displayString: string) => void;
  filteredItems: GlobalFilterItem[];
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const { data } = useGetSegmentList({
    queryParams: {
      page,
      pageSize: 25,
      search,
      skipGlobalFilters: true,
    },
  });

  if (!data?.list)
    return Array.from({ length: 10 }).map((_, index) => (
      <GlobalFilerListItemSkeleton key={index}>
        <Skeleton className='h-3 w-40' />
      </GlobalFilerListItemSkeleton>
    ));

  return (
    <>
      {data.list.map((segment) => {
        const isSelected = Boolean(
          filteredItems.find(
            (fi) => fi.type === 'global_segments' && fi.id === segment.id
          )
        );

        return (
          <GlobalFilterListItem
            onToggle={onToggle}
            isSelected={isSelected}
            key={segment.id}
            item={segment}
          >
            <div className='mt-1 flex items-center gap-2'>
              <p className='text-xs'>
                {segment.realestates.length} fastighet(er)
              </p>
            </div>
          </GlobalFilterListItem>
        );
      })}

      {data.meta.page_amount > 1 && (
        <div className='bg-background p-2'>
          <Pagination {...{ page, setPage, meta: data.meta }} />
        </div>
      )}
    </>
  );
}
